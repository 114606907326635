const gray = {
	gray100: "#F8F9FA",
	gray200: "#E9ECEF",
	gray300: "#DEE2E6",
	gray400: "#CED4DA",
	gray: "#ADB5BD",
	gray600: "#8590A6",
	gray700: "#495057",
	gray800: "#343A40",
	gray900: "#212529",
}

const blue = {
	blue100: "#CFE2FF",
	blue200: "#9EC5FE",
	blue300: "#6EA8FE",
	blue400: "#3D8BFD",
	blue: "#0D6EFD",
	blue600: "#0A58CA",
	blue700: "#084298",
	blue800: "#052C65",
	blue900: "#031633",
}

const indigo = {
	indigo100: "#E0CFFC",
	indigo200: "#C29FFA",
	indigo300: "#A370F7",
	indigo400: "#8540f5",
	indigo: "#6610F2",
	indigo600: "#520DC2",
	indigo700: "#3D0A91",
	indigo800: "#290661",
	indigo900: "#140330",
}

const purple = {
	purple100: "#E2D9F3",
	purple200: "#C5B3E6",
	purple300: "#A98EDA",
	purple400: "#8C68CD",
	purple: "#6F42C1",
	purple600: "#59359A",
	purple700: "#432874",
	purple800: "#2C1A4D",
	purple900: "#160D27",
}

const red = {
	red100: "#F8D7DA",
	red200: "#F1AEB5",
	red300: "#EA868F",
	red400: "#E35D6A",
	red: "#DC3545",
	red600: "#B02A37",
	red700: "#842029",
	red800: "#58151C",
	red900: "#2C0B0E",
}

const green = {
	green100: "#D1E7DD",
	green200: "#A3CFBB",
	green300: "#75B798",
	green400: "#479F76",
	green: "#198754",
	green600: "#146c43",
	green700: "#0F5132",
	green800: "#0A3622",
	green900: "#051B11",
}

const pink = {
	pink100: "#F7D6E6",
	pink200: "#EFADCE",
	pink300: "#E685B5",
	pink400: "#DE5C9D",
	pink: "#D63384",
	pink600: "#AB296A",
	pink700: "#801F4F",
	pink800: "#561435",
	pink900: "#2B0A1A",
}

const orange = {
	orange100: "#FFE5D0",
	orange200: "#FECBA1",
	orange300: "#FEB272",
	orange400: "#FD9843",
	orange: "#FD7E14",
	orange600: "#CA6510",
	orange700: "#984C0C",
	orange800: "#653208",
	orange900: "#331904",
}

const yellow = {
	yellow100: "#FFF3CD",
	yellow200: "#FFE69C",
	yellow300: "#FFDA6A",
	yellow400: "#FFCD39",
	yellow: "#FFC107",
	yellow600: "#CC9A06",
	yellow700: "#997404",
	yellow800: "#664D03",
	yellow900: "#332701",
}

const teal = {
	teal100: "#D2F4EA",
	teal200: "#A6E9D5",
	teal300: "#79DFC1",
	teal400: "#4DD4AC",
	teal500: "#20C997",
	teal600: "#1AA179",
	teal700: "#13795B",
	teal800: "#0D503C",
	teal900: "#06281E",
}

const cyan = {
	cyan100: "#CFF4FC",
	cyan200: "#9EEAF9",
	cyan300: "#6EDFF6",
	cyan400: "#3DD5F3",
	cyan: "#0DCAF0",
	cyan600: "#0AA2C0",
	cyan700: "#087990",
	cyan800: "#055160",
	cyan900: "#032830",
}

const themeColors = {
	primary: "#A64D79",
	secondary: "#9AC0D1",
	tertiary: "#F9F5E6",
	success: "#198754",
	danger: "#DC3545",
	warning: "#FFC107",
	info: "#E7F0FF",
	light: "#F6F6F6",
	dark: "#121212",
	black: "#1C1C1C",
	white: "#FCFCFC",
}

export { blue, cyan, gray, green, indigo, orange, pink, purple, red, teal, themeColors, yellow }
